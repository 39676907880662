import React from "react"
import { Container } from "react-bootstrap"
import { MainBanner } from "../components/banners/main-banner"

import { MainLayout } from "../components/layouts/layout"
import JkfImageGallery from "../components/sections/jkf-gallery-section"
import SEO from "../components/seo"

export default function Home({ data }) {
  return (
    <MainLayout activeNav="/products">
      <SEO title="Håndvaske" />
      <MainBanner
        image={data.banner.childImageSharp.base64.base64}
        title="Laminat bordplader"
        description="Bordplader til en god pris som holder i årevis"
      />
      <Container fluid>
        <JkfImageGallery images={data}></JkfImageGallery>
      </Container>
    </MainLayout>
  )
}

export const query = graphql`
  {
    files: allFile(filter: { relativeDirectory: { eq: "handvask/standard" } }) {
      edges {
        node {
          id
          childImageSharp {
            id
            fluid(maxHeight: 250, maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
      }
    }
    banner: file(relativePath: { eq: "banners/kitchen_black.jpg" }) {
      id
      childImageSharp {
        base64: sizes(base64Width: 1600, maxHeight: 800, quality: 100) {
          base64
        }
      }
    }
  }
`
